import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadApiService {
  constructor(private http: HttpClient) {}

  uploadImage(file): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
        'Content-Size': file.size,
        'Access-Control-Allow-Origin': '*',
      }),
    };
    return this.http.post(
      (window as any).smcUrl + 'happner-files/files/fieldveu-images/' + file.name,
      file,
      httpOptions
    );
  }
}
