import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MeshServiceProvidor } from '../service/mesh/mesh.service';
import { LoginDetail } from '../shared/interface/common';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { UrlService } from '../shared/services/url/url.service';
import { ResolutionResolverService } from 'src/app/shared/services/resolution-resolver/resolution-resolver.service';
import { ToasterData, userRoleConfig } from '../shared/constants/constants';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('loginForm', { static: false }) ngForm: NgForm;
  loginForm: FormGroup = this.fb.group({
    username: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]],
  });

  loginDissabled = false;
  errorMessage = '';
  loginModel = new LoginDetail();
  hidePassword = true;
  showEmailError = false;
  forgotPasswordStatus = false;
  resetPasswordConfirm = false;
  passwordSent = false;
  emailError = false;
  fetchRes = false;
  previousUrl;
  currentUrl;
  formChangesSub: Subscription;
  formValueChangeSub: Subscription;
  urlChangeSub: Subscription;
  userSub: Subscription;
  subscriber: Subscription[] = [];
  deviceType;
  resetStatus: boolean;
  readonly mobileResolution = ['MobilePortrait', 'MobileLandscape'];
  private loginSuccess = false;
  constructor(
    private meshServiceProvidor: MeshServiceProvidor,
    private router: Router,
    private urlService: UrlService,
    private _resolutionResolver: ResolutionResolverService,
    private cs: CommonService,
    public fb: FormBuilder
  ) {
    this._resolutionResolver._deviceType.subscribe((deviceType) => {
      this.deviceType = deviceType;
    });
  }
  login(loginForm): any {
    if (loginForm.valid) {
      this.loginDissabled = true;
      this.errorMessage = '';
      this.meshServiceProvidor
        .login(loginForm.value.username.toLowerCase(), loginForm.value.password)
        .then(() => {
          localStorage.setItem('lastLoggedIn', loginForm.value.username);
          this.meshServiceProvidor.getUserDetails();
          this.loginSuccess = true;
          // router navigates
        })
        .catch((err) => {
          this.errorMessage = err.message;
          this.loginDissabled = false;
        })
        .finally(() => {});
    }
  }

  ngOnInit(): void {
    this.urlChangeSub = (this.urlService as any).previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
    this.subscriber.push(this.urlChangeSub);

    this.userSub = this.meshServiceProvidor.userDetails$.subscribe((userDetail) => {
      if (!!userDetail && userDetail.fieldveuRole) {
        if (this.loginSuccess) {
          // check if CE Role, then navigate user to CE create template page
          localStorage.setItem('role', userDetail?.fieldveuRole);
          this.loginDissabled = false;

          if (localStorage.getItem('redirectTo')) {
            this.meshServiceProvidor.redirectRememberRouter();
            return;
          }
          if (
            userDetail.fieldveuRole === userRoleConfig.CE &&
            !this.mobileResolution.includes(this.deviceType)
          ) {
            this.router.navigate(['main', 'template']);
          } else {
            this.router.navigate(['main', 'device']);
          }
        }
      }
    });

    this.subscriber.push(this.userSub);
  }

  ngAfterViewInit(): void {
    this.formChangesSub = this.ngForm?.form?.statusChanges.subscribe((x) => {
      if (x === 'INVALID') {
        this.errorMessage = '';
      }
    });
    this.subscriber.push(this.formChangesSub);

    this.formValueChangeSub = this.ngForm?.form?.valueChanges.subscribe((x) => {
      if (x.username == null) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        localStorage.getItem('lastLoggedIn') != null
          ? this.ngForm?.form?.get('username').setValue(localStorage.getItem('lastLoggedIn'))
          : '';
        this.formValueChangeSub.unsubscribe();
      }
    });
    this.subscriber.push(this.formValueChangeSub);
  }
  onCancel(): any {
    this.resetPasswordConfirm = false;
    this.passwordSent = false;
  }
  checkUser(e) {
    if (e.errors == null) {
      this.emailError = false;
    } else if ('email' in e.errors) {
      if (e.errors.email === false) {
        this.emailError = false;
      }
    }
  }
  onSubmit(): any {
    this.fetchRes = true;

    this.meshServiceProvidor
      .forgotPassword(this.loginModel.username, this.update)
      .then((res) => {
        if (res) {
          this.fetchRes = false;
          this.passwordSent = true;
        }
      })
      .catch((err) => {
        this.resetStatus = true;
        this.fetchRes = false;
        const data: ToasterData = {
          type: 'error-custom',
          message: err.toString(),
        };

        this.cs.openToaster(data);
      });
  }
  update = (err, data) => {};
  forgotPassword(e): any {
    if (e.errors == null) {
      this.resetPasswordConfirm = true;
    } else if ('email' in e.errors) {
      if (e.errors.email === false) {
        this.resetPasswordConfirm = true;
      }
    } else {
      this.emailError = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriber.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }
}
